<template>
  <section class="account-upgrade-area">
    <vx-card title="Billing Details" class="mb-base">
      <AccountBillingInfo ref="billing" />
    </vx-card>

    <vx-card title="Order Summary" class="mb-base">
      <div class="vx-row">
        <div class="vx-col md:w-2/5 w-full">
          <card :data="currentPlan" :disabled-button="true" />
        </div>
        <div class="vx-col md:w-3/5 w-full">
          <vx-card>
            <p class="font-semibold mb-3">Price Details</p>
            <div class="flex justify-between mb-5" v-for="(item, i) in item.lineItems" :key="i">
              <span class="text-grey">{{ item.description }}</span>
              <span>{{ item.formattedAmount }}</span>
            </div>
            <vs-divider class="mb-base mt-base" />
            <div class="flex justify-between font-semibold mb-3">
              <span>To Pay</span>
              <span>{{ item.formattedToPay }}</span>
            </div>
          </vx-card>
        </div>
      </div>
    </vx-card>

    <vx-card title="Payment Details" class="mb-base">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <div v-if="hasCards">
            <PaymentCards
              v-model="cardSelected"
              @input="newPaymentCard = false"
              :disabledColumns="['default']" />
            <div class="vx-row mt-2" v-if="!newPaymentCard">
              <div class="vx-col w-full">
                <vs-button
                  @click="newPaymentCard = true; cardSelected = null">Use New Card</vs-button>
              </div>
            </div>
          </div>
          <CreditCardForm
            ref="cardForm"
            v-model="cardSelected"
            v-validate="'required|stripe'"
            name="payment_details"
            data-vv-as="payment details"
            v-if="cardFormActive"
            :disabled-button="true" />
          <vs-alert class="mt-3" color="danger" v-if="errors.has('payment_details')">
            <span class="text-xs">{{errors.first('payment_details')}}</span>
          </vs-alert>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <p class="mb-5 mt-5">Your credit card information are securely stored in a vault by Stripe, our third party Payment System gateway.</p>
          <p>For easy access and retrieval, we only store the last 4 digits of your credit card and its expiration date.</p>
        </div>
        <div class="vx-col w-full mt-base flex justify-center">
          <vs-button
            color="success"
            size="large"
            @click="purchase">Purchase</vs-button>
        </div>
      </div>
    </vx-card>
  </section>
</template>

<script>
import Card from '@/views/pages/upgrade/components/Card.vue';
import AccountBillingInfo from '@/views/account/components/AccountBillingInfo';
import CreditCardForm from '@/views/account/components/components/CreditCardForm';
import PaymentCards from '@/views/account/components/components/PaymentCards';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    Card,
    AccountBillingInfo,
    CreditCardForm,
    PaymentCards
  },
  data: () => ({
    cardSelected: null,
    newPaymentCard: false
  }),
  computed: {
    ...mapGetters('accounts', [
      'getPlanById'
    ]),
    currentPlan() {
      return this.getPlanById(this.$route.params.id);
    },
    ...mapState('order', ['item', 'cards']),
    hasCards() {
      try {
        return this.cards.items.length > 0
      } catch (error) {
        return false
      }
    },
    cardFormActive() {
      return (!this.hasCards || this.newPaymentCard)
    }
  },
  methods: {
    async purchase() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        if (this.cardFormActive) {
          return this.$refs.cardForm.createToken().then(result => this.upgrade(result))
        }
        this.upgrade(this.cardSelected);
      }
    },
    async upgrade(source) {
      await this.$store.dispatch('order/upgrade', {
        data: {
          id: this.$route.params.id,
          source,
          billingDetails: this.$refs.billing.form
        }
      }).then(result => {
        if (result) this.$router.push(`/upgrade/thankyou?upgradeId=${this.$route.params.id}`)
      })
    }
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('order/cards'),
      this.$store.dispatch('order/item', { id: this.$route.params.id })
    ])
  },
  async mounted() {
  }
}
</script>

<style lang="scss">
.account-upgrade-area {
  .billing-info-block {
    .buttons {
      display: none !important;
    }
  }
}
</style>